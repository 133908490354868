export const FETCH_SERVICOS_SUCCESS = "FETCH_SERVICOS_SUCCESS";

const fetchServicosSuccess = servicos => ({
  type: FETCH_SERVICOS_SUCCESS,
  payload: servicos
});

// buscar servicos
export const fetchServicos = servicos => {
  return dispatch => {
    dispatch(fetchServicosSuccess(servicos));
  };
};
